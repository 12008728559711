
import http from '@/utils/http'
import Rest from '@/utils/http/Rest'

export default class Business extends Rest {
  /**
   * 批量保存企业人员信息
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  batchSave (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/batchSave`,
      data
    })
  }

  /**
   * 获取供应商分页信息
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  supplierPage (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/company/supplier/page',
      params
    })
  }

  /**
   * 获取访谈人员分页列表
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  getAuthcenterPage (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/authcenter/base/user/page',
      params
    })
  }

  /**
   * 条件查询合同列表
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  contractList (params) {
    return http({
      hideLoading: true,
      method: 'get',
      url: '/api/finance/result/getContractsByBusNo',
      params
    })
  }

  /**
   * 根据人员id查看人员关系列表
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  getPersonContactList (params) {
    return http({
      hideLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`,
      params
    })
  }

  /**
   * 条件查询补充合同列表
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  contractPage (params) {
    return http({
      method: 'get',
      url: '/api/finance/result/getContractPage',
      params
    })
  }

  /**
   * 查询主借人信息
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  companys (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/result/companys',
      params
    })
  }

  /**
   * 查询对应企业和员工信息
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  listbylabel (params) {
    return http({
      method: 'get',
      url: '/api/company/supplier/listbylabel',
      params
    })
  }

  /**
   * 放款计划预览
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  previewRepayPlan (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/result/previewRepayPlan',
      params
    })
  }

  /**
   * 供应商签章人列表
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  getGysPersonnelList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/company/contact/queryPersonnelList',
      params
    })
  }

  /**
   * 合同列表列表
   * @param data 请求参数
   * @returns {AxiosPromise}
   */
  getContactlList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/finance/contract/list',
      params
    })
  }
}
