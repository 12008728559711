import { getToken } from '@/utils/auth/auth-storage'
import { http } from '@/utils/http/index'

// 组件支持的文件格式
export const supportFileTypes = ['doc', 'docx', 'xlsx', 'xls', 'pdf']
// 图片格式
export const supportImagesFileType = ['png', 'jpg', 'gif', 'bmp', 'jpeg']
export const allSupportFileTypes = [
  ...supportFileTypes,
  ...supportImagesFileType
]

const wordUrl = '/wv/wordviewerframe.aspx?WOPISrc='
const excelUrl = '/x/_layouts/xlviewerinternal.aspx?WOPISrc='

/**
 * 根据文件id 生成word文件的在线预览地址
 * @param {文件id} fileId
 */
export const buildImageOrPefUrl = async (fileId, isPdf) => {
  const response = await downloadPreviewFile(fileId)
  // 构造文件类型信息
  const defaultType = isPdf ? { type: 'application/pdf' } : {}
  // 生产
  return window.URL.createObjectURL(new Blob([response.data], defaultType))
}

export const downloadPreviewFile = (params) => {
  return http({
    method: 'get',
    url: `${process.env.VUE_APP_FILE_URL}/api/file/file/view?keyId=${params}`,
    params,
    responseType: 'blob'
  })
}

const isWord = (type) => type === 'doc' || type === 'docx'

/**
 * 创建在线预览地址
 * @param {*} oosUrl
 * @param {*} fileUrl
 * @param {*} fileId
 * @param {*} fileType
 * @returns
 */
export const createOosUrl = (oosUrl, fileUrl, fileType, fileId) => {
  const configUrl = isWord(fileType) ? wordUrl : excelUrl
  if (fileUrl.includes('https')) {
    fileUrl = fileUrl.replace('https', 'http')
  }
  return oosUrl + configUrl + fileUrl + '/wopi/files/' + fileId + '&access_token=' + getToken()
}
