<template>
<div style="position:relative">
    <el-input
    :value="value"
    v-bind="$attrs"
    v-on="currentListeners"
    @blur="blur"
    @focus="focus"
  ></el-input>
  <div v-if="noonAmount&&OnFocus" class="noonAmount">{{noonAmount}}</div>
</div>
</template>

<script>
import { EmojiReg } from '@/config/reg-config'
export default {
  name: 'amountInput',
  props: {
    value: {}
  },
  data () {
    return {
      input: '',
      noonAmount: '',
      OnFocus: false
    }
  },
  computed: {
    currentListeners () {
      return Object.assign({}, this.$listeners, {
        input: this.handleInput
      })
    }
  },
  methods: {
    focus (e) {
      const value = e.target.value.replace(EmojiReg, '')
      this.OnFocus = true
      if (this.$attrs.type === 'Y') { // 元 0-1亿
        // this.fValue = this.convertCurrency(value)

        console.log(this.convertCurrency(value))
        if (value > 99999999.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === 'G') {
        if (value > 99999999999999.99 && value) {

        }
      } else if (this.$attrs.type === '10Y') { // 元 0-10亿
        if (value > 999999999.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === '1000Y') { // 1000Y 0~1000亿
        if (value > 99999999999.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === 'WY') { // 万元
        if (value > 999999999.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === 'YY') { // 亿元
        if (value > 99999.99 && value) {
          return
        }

        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === 'K') { // 千元 0~1000
        if (value > 999.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === '十亿') { // 亿元 上线为10亿
        if (value > 99.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      }
    },
    // 失去焦点验证小数点后有无数字
    blur () {
      this.OnFocus = false
      let value = this.value
      if (!value) {
        return
      }
      const arr = value.toString().split('.')
      if (arr.length > 1 && !arr[1]) {
        value = arr[0]
      }
      this.$emit('input', value)
    },
    handleMoney (num) {
      if (!num) {
        this.noonAmount = ''
      }
      // 首先先声明一个金额单位数组
      const AmountUnitlist = ['个', '十', '百', '千', '万', '十万', '百万', '千万', '亿', '十亿', '百亿', '千亿']
      // 将数字金额转为字符串
      const strnum = num.toString()
      // 声明一个变量用于接收金额单位
      let AmountUnit = ''
      let newNum = ''
      // 判断一下传进来的金额是否包含小数点
      if (strnum.indexOf('.') !== -1) {
        // 若有则将小数点前的字符截取出来
        newNum = strnum.substring(0, strnum.indexOf('.'))
      } else {
        // 没有则直接等于原金额
        newNum = strnum
      }
      AmountUnit = AmountUnitlist[newNum.length - 1]

      const money = { num: 0, unit: '' }
      // 保留2位小数
      money.num = (strnum * 1).toFixed(2)
      // 接收单位
      money.unit = AmountUnit
      return money
    },

    convertCurrency (money) {
    // 汉字的数字
      const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
      // 基本单位
      const cnIntRadice = ['', '拾', '佰', '仟']
      // 对应整数部分扩展单位
      const cnIntUnits = ['', '万', '亿', '兆']
      // 对应小数部分单位
      const cnDecUnits = ['角', '分', '毫', '厘']
      // 整数金额时后面跟的字符
      const cnInteger = ''
      // 整型完以后的单位
      const cnIntLast = ''
      // 最大处理的数字
      const maxNum = 999999999999999.9999
      // 金额整数部分
      let integerNum
      // 金额小数部分
      let decimalNum
      // 输出的中文金额字符串
      let chineseStr = ''
      // 分离金额后用的数组，预定义
      let parts
      if (money === '') { return '' }
      money = parseFloat(money)
      if (money >= maxNum) {
        // 超出最大处理数字
        return ''
      }
      if (money === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger
        return chineseStr
      }
      // 转换为字符串
      money = money.toString()
      if (money.indexOf('.') === -1) {
        integerNum = money
        decimalNum = ''
      } else {
        parts = money.split('.')
        integerNum = parts[0]
        decimalNum = parts[1].substr(0, 4)
      }
      // 获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0
        var IntLen = integerNum.length
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1)
          var p = IntLen - i - 1
          var q = p / 4
          var m = p % 4
          if (n === '0') {
            zeroCount++
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0]
            }
            // 归零
            zeroCount = 0
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
          }
          if (m === 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q]
          }
        }
        chineseStr += cnIntLast + '圆'
      }
      // 小数部分
      if (decimalNum !== '') {
        var decLen = decimalNum.length
        for (let i = 0; i < decLen; i++) {
          const n = decimalNum.substr(i, 1)
          if (n !== '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i]
          } else if (n === '0') {
            chineseStr += cnNums[Number(n)]
          }
        }
      }
      if (chineseStr === '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger
      } else if (decimalNum === '') {
        chineseStr += cnInteger + '整'
      }
      return chineseStr
    },

    handleInput (e) {
      // 替换掉表情输入
      let value = e.replace(EmojiReg, '')
      // 替换 首位为0 输入不为 . 的字符
      const numberarr = value.split('')
      if (
        numberarr[0] === '0' &&
        numberarr.length > 1 &&
        numberarr[1] !== '.'
      ) {
        return
      }
      // 替换 首位为 . 和多个 .
      if (value.indexOf('.') > -1) {
        const arr = value.split('.')
        if (!arr[0]) {
          return
        }
        if (arr.length > 2) {
          return
        }
      }

      if (this.$attrs.type === 'Y') { // 元 0-1亿
        // this.fValue = this.convertCurrency(value)

        console.log(this.convertCurrency(value))
        if (value > 99999999.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === 'G') {
        if (value > 99999999999999.99 && value) {
          return
        }
      } else if (this.$attrs.type === '10Y') { // 元 0-10亿
        if (value > 999999999.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === '100Y') { // 元 0-100亿
        if (value > 9999999999.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === '1000Y') { // 1000Y 0~1000亿
        if (value > 99999999999.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === 'WY') { // 万元
        if (value > 999999999.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === 'YY') { // 亿元
        if (value > 99999.99 && value) {
          return
        }

        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === 'K') { // 千元 0~1000
        if (value > 999.99 && value) {
          return
        }
        this.noonAmount = this.handleMoney(value).unit
      } else if (this.$attrs.type === 'BL') { // 0.01~100 用于比例
        if (value && value > 100) {
          return
        } else if (value && value < 0) {
          return
        }
      } else if (this.$attrs.type === '十亿') { // 亿元 上线为10亿
        if (value > 99.99 && value) {
          return
        }
      }
      if (this.$attrs.type === 'ZS') {
        if (value === 0 || value === '0') {
          value = ''
        }
        value = value
          .replace(/[^\d]/g, '')
      }
      // 只能为数字和最多两位小数
      value = value
        .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/^\./g, '') // 验证第一个字符是数字而不是
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.$emit('input', value)
    }
  }
}
</script>
<style lang="scss" scoped>
.noonAmount{
  position: absolute;
  top: 26px;
  left: 15px;
  font-size: 12px;
  -webkit-transform: scale(0.90);
  color: #C0C4CC;
}
</style>
