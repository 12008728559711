<template>
<div>
  <iframe v-if="isIframe&&!isOpen" width="100%" height="100%" style="flex:1" :src="previewUrl" frameborder="0" id='Iframe' />
  <!-- <img v-if='!isIframe&&!isOpen' :src="previewUrl" :width="imgWidth" :height="imgHeight"  style="margin:0 auto ;display:block" /> -->
  <div style=" height: 100%;width: 100%;overflow: hidden; display: flex;justify-content: center;align-items: center;" max-width="imgWidth" max-height="imgHeight" v-if='!isIframe&&!isOpen'>
  <img v-if='!isIframe&&!isOpen' :src="previewUrl"   style="height: auto;width: auto;object-fit: cover; max-width: 100%; max-height: 100%;" />
  </div>
</div>

</template>

<script>
import {
  allSupportFileTypes,
  buildImageOrPefUrl,
  createOosUrl,
  supportImagesFileType
} from './pre-view'
export default {
  name: 'preView',
  props: {
    // 是否打开新页面预览
    isOpen: {
      type: Boolean,
      default: false
    },
    // 文件类型
    fileType: {
      type: String,
      default: ''
    },
    // 文件Id
    fileId: {
      type: String,
      default: ''
    },
    // 在线预览服务地址
    oosUrl: {
      type: String,
      default: process.env.VUE_APP_ONLINE_FILE
    },
    // 文件服务地址
    fileUrl: {
      type: String,
      default: process.env.VUE_APP_FILE_URL
    },
    // 重新构建路由
    count: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      previewUrl: '',
      isIframe: false,
      isNeedRelease: false,
      imgWidth: '',
      imgHeight: ''
    }
  },
  watch: {
    count: {
      handler () {
        this.clearMemory()
        this.initPreview()
        this.$nextTick(() => {
          setTimeout(() => {
            console.log(document.getElementById('Iframe').contentWindow.document.getElementsByTagName('embed')
            )
          }, 2000)
        })
      }
    }
  },
  methods: {

    // 获取图片的原始宽高
    getImageInfo (url) {
      const self = this
      const img = new Image()
      img.src = url
      img.onload = function () {
        self.imgWidth = img.width + 'px'
        self.imgHeight = img.height + 'px'
        console.log('图片原始高度', img.height)
        console.log('图片原始宽度', img.width)
      }
    },
    async initPreview () {
      this.clearMemory()
      const { url, isIframe, isNeedRelease } = await this.buildUrl()
      this.previewUrl = url
      this.isIframe = isIframe
      console.log(this.previewUrl, this.isIframe, this.isOpen, '2222')
      if (!isIframe) { // 图片获取原始宽高
        this.getImageInfo(this.previewUrl)
      }
      console.log(this.isOpen)
      if (this.isOpen) {
        localStorage.setItem('fileIdInfo', JSON.stringify({ url: this.previewUrl, isIframe: this.isIframe }))
        window.open('/preView.html')
        return
      }

      this.isNeedRelease = isNeedRelease
    },
    /*
    在每次调用 createObjectURL() 方法时，都会创建一个新的 URL 对象，
    即使你已经用相同的对象作为参数创建过。当不再需要这些 URL 对象时，
    每个对象必须通过调用 URL.revokeObjectURL() 方法来释放。
    浏览器在 document 卸载的时候，会自动释放它们，
    但是为了获得最佳性能和内存使用状况，你应该在安全的时机主动释放掉它们。
    */
    clearMemory () {
      if (this.isNeedRelease && this.url) {
        window.URL.revokeObjectURL(this.url)
      }
    },
    async buildUrl () {
      const { fileId, oosUrl, fileUrl } = this
      let { fileType } = this
      console.log(fileId, fileType, 'fileType')
      if (!fileId || typeof fileId !== 'string') {
        this.warning(`文件id参数有误：[${fileId}]`)
        throw new Error(`文件id参数有误：[${fileId}]`)
      }
      // 文件后缀大小写不一致 全部转为小写
      if (fileType) {
        fileType = fileType.toLowerCase()
      }
      if (!fileType || !allSupportFileTypes.includes(fileType)) {
        this.warning(`文件类型有误：[${fileType}]`)
        throw new Error(`文件类型有误：[${fileType}]`)
      }

      let url
      let isIframe = true
      let isNeedRelease = false

      const isPdf = fileType === 'pdf'
      // url 参数
      if (supportImagesFileType.includes(fileType) || isPdf) {
        // 图片或者pdf
        url = await buildImageOrPefUrl(fileId, isPdf)
        url = url + '#toolbar=0'
        isNeedRelease = true
      } else {
        // word excel
        url = createOosUrl(oosUrl, fileUrl, fileType, fileId)
      }
      // 图片 使用image 标签 其它使用ifrmae
      if (supportImagesFileType.includes(fileType)) {
        isIframe = false
      }
      return {
        url,
        isNeedRelease,
        isIframe // 是否使用iframe 进行预览
      }
    }
  },
  beforeDestroy () {
    this.clearMemory()
  }
}
</script>
<style lang="scss" scoped>
.newsCover {
   max-height: 1024px;
 max-width: 1280px;
overflow: hidden;
}
.newsCover>img {
   max-height: 1024px;
 max-width: 1280px;
object-fit: cover;
}
</style>
