import { formatDate } from '@/utils/auth/common'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import termOfValidity from '../../financing-decision-manage/components/term-of-validity.vue'
import amountInput from '@/components/input/amount-input/amount-input.vue'
// import NewBaseSelect from '@/components/common/base-select/new-base-select.vue'
// import Storage from '@/utils/storage'

export const MeetingConfig = (content) => {
  const pickerOptions = {
    disabledDate (v) {
      return v.getTime() <= content.formData.resultValidTimeStart
    }
  }

  const validTimeNumValidator = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请选择有效期'))
    } else {
      if (!content.formData.validTimeUnit) {
        return callback(new Error('请选择有效期单位'))
      } else {
        return callback()
      }
    }
  }
  return [
    {
      label: '有效期',
      prop: 'validTimeNum',
      rules: [
        {
          required: true,
          validator: validTimeNumValidator,
          trigger: ['change', 'blur']
        }
      ],

      span: 8,
      attrs: {

        placeholder: '请输入有效期',
        maxLength: 5,
        type: 'ZS',
        validTimeUnit: content.formData.validTimeUnit
      },
      on: {
        blurInput () {
          content.verification()
        },
        update (val) {
          content.changeValidTimeUnit(val)
        }
      },
      tag: termOfValidity
    },

    {
      label: '股东决议有效期开始时间', // 开始时间
      prop: 'resultValidTimeStart',
      rules: [
        {
          required: true,
          message: '请选择股东决议有效期',
          trigger: ['change', 'blur']
        }
      ],

      span: 8,
      tag: 'el-date-picker',
      attrs: {
        'value-format': 'timestamp',

        placeholder: '请选择股东决议有效期',
        editable: false,
        clearable: false
      },
      on: {
        change (data) {
          content.verification()
        }
      }
    },
    {
      label: '股东决议有效期结束时间', // 结束时间
      prop: 'resultValidTimeEnd',
      rules: [
        {

          required: true,
          message: '请选择股东决议有效期',
          trigger: ['change', 'blur']
        }
      ],

      span: 8,
      tag: 'el-date-picker',
      attrs: {
        'value-format': 'timestamp',

        placeholder: '请选择股东决议有效期',
        editable: false,
        clearable: false,
        'picker-options': pickerOptions
      }
    },
    {
      label: '担保额度(万元)',
      prop: 'guaranteeQuota',
      rules: [
        { required: true, message: '请输入担保额度', trigger: ['blur', 'change'] }
      ],
      span: 8,

      attrs: {

        placeholder: '请输入担保额度',

        type: 'YY'
      },
      tag: amountInput
    },
    {
      label: '是否为循环额度',
      prop: 'isCycle',
      rules: [
        { required: true, message: '请选择是否为循环额度', trigger: ['blur', 'change'] }
      ],
      span: 8,

      attrs: {
        options: [{ keyId: '2', name: '是' }, { keyId: '1', name: '否' }],
        selectedField: ['keyId', 'name']
      },
      tag: BaseSelect
    },
    {
      label: '业务审批时间',
      prop: 'busiApproveTime',
      rules: [
        {
          required: true,
          message: '请选择业务审批时间',
          trigger: ['change', 'blur']
        }
      ],
      span: 8,
      tag: 'el-date-picker',
      attrs: {
        disabled: true,
        placeholder: '请选择业务审批时间',
        editable: false
      }
    }
  ]
}
// 表格form
export const dataTable = (content) => {
  const selectable = function (row, index) {
    return row.isGenerate === '1'
  }
  const renderHeader = function (h, { column, $index }) {
    return [
      h(
        'el-checkbox', {
          props: {
            value: content.checkAll,
            indeterminate: content.isIndeterminate
          },
          on: {
            change: content.updateAllSelected // 选中事件
          }
        }
      ),
      column.label
    ]
  }
  return [
    {
      type: 'selection',
      width: '50px',
      selectable: selectable
    },
    {
      label: '序号',
      prop: 'index',
      width: '50px'
    },
    {
      label: '文件类型名称',
      prop: 'documentName',
      width: '150px',
      HiddenOverflow: false,
      showOverflowTooltip: false
    },
    // {
    //   label: '盖章类别',
    //   prop: 'positionName',
    //   isHidden: content.suppliersSealType === 0
    // },
    // {
    //   label: '电子章类型',
    //   prop: 'signerType',
    //   isHidden: content.suppliersSealType === 0,

    //   formatter: row => {
    //     const [obj] = getDictLists('SEAL_TYPE').filter(item => item.dictId === row.signerType)
    //     return obj ? obj.dictName : '-'
    //   }
    // },
    // {
    //   label: '电子章签署人',
    //   prop: 'sealAdminId',
    //   width: '200px',
    //   HiddenOverflow: true,
    //   isHidden: content.suppliersSealType === 0
    // },
    {
      label: '最新制作人',
      prop: 'uplaodUserName',
      width: '100px'
    },
    {
      label: '最新制作时间',
      prop: 'uploadTime',
      width: '120px',
      formatter: row => {
        return formatDate(row.uploadTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    // {
    //   label: '模板文件',
    //   HiddenOverflow: true,
    //   prop: 'templateFile',
    //   width: '120px'
    // },
    {
      label: '文件',
      HiddenOverflow: true,
      prop: 'fileList',
      'min-width': '400px'
    },
    {
      label: '需盖电子章',
      HiddenOverflow: true,
      prop: 'isSign',
      width: '120',
      'render-header': renderHeader
    },
    {
      label: '操作',
      HiddenOverflow: true,
      width: '145px',
      prop: 'action',
      align: 'left'
    }
  ]
}
// 基础表格form
export const dataTables = (content) => {
  const selectable = function (row, index) {
    return row.isGenerate === '1'
  }
  return [
    {
      type: 'selection',
      width: '50px',
      selectable: selectable
    },
    {
      label: '序号',
      prop: 'index',
      width: '50px'
    },
    {
      label: '文件类型名称',
      prop: 'documentName',
      width: '160px'
    },
    {
      label: '最新制作人',
      prop: 'uplaodUserName',
      width: '100px'
    },
    {
      label: '最新制作时间',
      prop: 'uploadTime',
      width: '140px',
      formatter: row => {
        return formatDate(row.uploadTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    {
      label: '文件',
      HiddenOverflow: true,
      prop: 'fileList'
    },
    {
      label: '需要盖章',
      HiddenOverflow: true,
      prop: 'isSign',
      width: '120'
    },
    {
      label: '操作',
      HiddenOverflow: true,
      width: '140px',
      prop: 'action',
      align: 'left'
    }
  ]
}
export const fileTable = () => {
  return [

    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '材料题名',
      prop: 'documentName'
    },
    {
      label: '上传人',
      prop: 'uplaodUserName'
    },
    {
      label: '上传时间',
      prop: 'uploadTime',
      formatter: row => {
        return formatDate(row.uploadTime, 'YY-MM-DD')
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
export const formConfig = () => {
  const span = 8
  return [
    {
      label: '决议时间',
      prop: 'resolutionTime',
      attrs: {
        type: 'date',
        placeholder: '请选择',
        prefixIcon: 'el-icon-date'
      },
      tag: 'el-date-picker',
      span
    },
    {
      label: '决议地点',
      prop: 'resolutionaderr',
      attrs: {
        placeholder: '请输入'
      },
      span
    },
    {
      label: '实到股东数',
      prop: 'resolutioncount',
      attrs: {
        placeholder: '请输入'
      },
      span
    },
    {
      label: '应到股东数',
      prop: 'ycount',
      attrs: {
        placeholder: '请输入'
      },
      span
    },
    {
      label: '文件生成时间',
      prop: 'createTime',
      attrs: {
        type: 'date',
        placeholder: '请选择'
      },
      tag: 'el-date-picker',
      span
    }
  ]
}
export const formConfig1 = () => {
  const span = 8
  return [
    {
      label: '保理合同编码',
      prop: 'code',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      span
    },
    {
      label: '保理服务合同签署见证人',
      prop: 'materialTitle',
      attrs: {
        placeholder: '请输入'
      },
      span
    },
    {
      label: '文件签署时间',
      prop: 'filecreate',
      attrs: {
        type: 'date',
        placeholder: '请选择'
      },
      tag: 'el-date-picker',
      span
    }
  ]
}

// 选择签章人弹窗表格
export const signatoryTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '50px'
    },
    {
      label: '文件类型',
      prop: 'finFileListName',
      'min-width': '100'
    },
    {
      label: '文件来源',
      prop: 'fileSource',
      'min-width': '70',
      formatter: row => {
        return row.fileSource === 0 ? '系统生成' : '线下上传'
      }
    },
    {
      label: '合同名称',
      prop: 'finFileListDetailName',
      'min-width': '130'
      // isHidden: true
    },
    {
      label: '签章模式',
      prop: 'category',
      'min-width': '100',
      HiddenOverflow: true
      // formatter: row => {
      //   // 签署人类型1:个人 / 2:企业-页面企业个人
      //   return row.signerType === '1' ? '' : row.signName
      // }
    },
    {
      label: '盖章主体',
      prop: 'signName',
      'min-width': '140',
      HiddenOverflow: true,
      formatter: row => {
        // 签署人类型1:个人 / 2:企业-页面企业个人
        return row.signerType === '1' ? '' : row.signName
      }
    },
    {
      label: '盖章类别',
      prop: 'name',
      'min-width': '80',
      HiddenOverflow: true

    },
    {
      label: '电子章类型',
      prop: 'signerType',
      'min-width': '80',
      HiddenOverflow: true

    },
    {
      label: '电子章签署人',
      prop: 'sealAdminId',
      'min-width': '80',
      HiddenOverflow: true
    },
    {
      label: '签署位置',
      prop: 'positionType',
      'min-width': '80',
      HiddenOverflow: true
    },
    {
      label: '签署位置明细',
      prop: 'posKeyword',
      'min-width': '120',
      HiddenOverflow: true
    },
    {
      label: '操作',
      prop: 'previewShow',
      'min-width': '70',
      HiddenOverflow: true
    },
    {
      prop: 'action',
      width: '60px'
    }
  ]
}

// 还款计划表格
export const repaymentPlanConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '50px'
    },
    {
      label: '还款日期',
      prop: 'preRepayDate',
      minwidth: '200px',
      HiddenOverflow: false
    },
    {
      label: '还款金额(元)',
      prop: 'preRepayAmount',
      minwidth: '200px',
      HiddenOverflow: false
    },
    {
      label: '还款合同',
      prop: 'contractRepaymentArr',
      minwidth: '230px',
      HiddenOverflow: false
    },
    {
      label: '还款条件',
      prop: 'repayCondition',
      minwidth: '200px',
      HiddenOverflow: false,
      maxlength: '500'
    },
    {
      label: '操作',
      prop: 'action',
      width: '80'
    }
  ]
}
// 文件履历查询
export const docHistoryFormConfig = (content) => {
  const span = 6
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      span
    },
    {
      label: '文件类型名称',
      prop: 'documentId',
      attrs: {
        clearable: true,
        placeholder: '请输入',
        options: content.fileTypeLists,
        selectedField: ['documentId', 'documentName']
      },
      span,
      tag: BaseSelect
    },
    {
      label: '操作人',
      prop: 'operatorName',
      attrs: {
        placeholder: '请输入'
      },
      span
    },
    {
      label: '操作日期区间',
      prop: 'operateTime',
      isHidden: content.fold,
      attrs: {
        type: 'daterange',
        rangeSeparator: '至',
        'value-format': 'timestamp',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期'
      },
      tag: 'el-date-picker',
      on: {
        change: (data) => {
          // content.queryParas.operateTimeEnd = formatDate(data[1], 'YY-MM-DD')
          // content.queryParas.operateTimeStart = formatDate(data[0], 'YY-MM-DD')
          content.queryParas.operateTimeEnd = data[1]
          content.queryParas.operateTimeStart = data[0]
        }
      },
      span
    }
  ]
}

// 文件履历列表
export const docHistoryTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '50px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      HiddenOverflow: false
    },
    {
      label: '文件类型名称',
      prop: 'documentName',
      HiddenOverflow: false,
      align: 'left'
    },
    {
      label: '文件名称',
      prop: 'fileName',
      HiddenOverflow: false,
      align: 'left'
    },
    {
      label: '操作人',
      prop: 'operatorName',
      HiddenOverflow: false,
      maxlength: '500'
    },
    {
      label: '操作时间',
      prop: 'operateTime',
      HiddenOverflow: false,
      maxlength: '500',
      formatter: row => {
        return formatDate(row.operateTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    {
      label: '操作类型',
      prop: 'operateType',
      HiddenOverflow: false,
      formatter: row => {
        const [obj] = content.operateTypeOptions.filter((item) => item.dictId === row.operateType)
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '90',
      HiddenOverflow: false,
      align: 'left'
    }
  ]
}
// 审核意见
export const approvalFrom = (content) => {
  return [
    // {
    //   label: '复核',
    //   prop: 'reviewUserId',
    //   attrs: {
    //     placeholder: '合同制作岗非当前人员复核',
    //     options: content.optionsManager,
    //     selectedField: ['riskManagerId', 'riskManagerName']
    //   },
    //   rules: [
    //     {
    //       required: true,
    //       message: '请选择复核人',
    //       trigger: 'change'
    //     }
    //   ],
    //   span: 24,
    //   tag: NewBaseSelect,
    //   isHidden: Storage.getLocal('contractInfo').reviewResult
    // },
    {
      // label: '备注',
      prop: 'desc',
      attrs: {
        placeholder: '请输入',
        rows: '8',
        type: 'textarea'
      },
      span: 24
    }
  ]
}
