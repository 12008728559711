<template>
  <div>
    <AmountInput v-bind="$attrs" v-on="$listeners" style="width: 60%" @blur="blur" />
    <base-select
      style="width: 30%; margin-left: 10%"
      :disabled='$attrs.disabled'
      v-model="validTimeUnit"
      :options="options"
      :selectedField="['dictId', 'dictName']"
    />
  </div>
</template>

<script>
import { getDictLists } from '@/filters/fromDict'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
export default {
  components: { AmountInput, BaseSelect },
  props: {},
  data () {
    return {

    }
  },
  // 计算属性 类似于data概念
  computed: {
    options () {
      return getDictLists('DATE_UNIT')
    },
    validTimeUnit: {
      get () {
        return this.$attrs.validTimeUnit
      },
      set (val) {
        // this.$forceUpdate()
        this.$emit('update', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    blur () {
      this.$emit('blurInput', '')
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
</style>
