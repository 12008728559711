<!-- 选择签章人，船新版本 -->
<template>
<div>
  <base-dialog
    :visible.sync="visible"
    :showFooter="false"
    title="选择电子签署人"
    class="selectSigner"
  >
    <base-table
      :columns="columns"
      :showPage="false"
      :tableAttrs="{
        data: signListData,
        stripe: false,
        border: true,
        spanMethod: objectSpanMethod,
      }"
      :isCaculateHeight="false"
      ref="signListData"
      :webPage="true"
      class="table"
    >
      <template slot="index" slot-scope="scope">{{
        scope.$index + 1
      }}</template>
      <!-- 机构类型 -->
      <template slot="category" slot-scope="scope">
        <base-select
          v-model="scope.row.category"
          :options="categoryOptions"
          :filterable="true"
          :disabled="scope.row.documentType == '20'"
          :selectedField="['value', 'label']"
          @change='changeCategory(scope.row,scope.$index)'
        ></base-select>
      </template>
      <!-- 盖章主体   更具机构不同盖章主体不同-->
      <template slot="signName" slot-scope="scope">
        <base-select
          v-model="scope.row.signName"
          :options="gysOptions"
          :filterable="true"
          :disabled="scope.row.documentType == '20'"
          :selectedField="['enterpriseName', 'enterpriseName']"
          @change="changeSignName(scope.row,scope.$index)"
          v-if="scope.row.category==='0'"
        ></base-select>
          <base-select
          v-model="scope.row.signName"
          :options="mainPartData"
          :filterable="true"
          :selectedField="['enterpriseName', 'enterpriseName']"
            @change="changeSignName(scope.row,scope.$index)"
         v-else
        ></base-select>
       </template>
      <!-- 盖章类别 -->
      <template slot="name" slot-scope="scope">
        <el-input
          v-model="scope.row.positionConfig.name"
          :disabled="scope.row.documentType == '20'"
        ></el-input>
      </template>
      <!-- 电子章类型 -->
      <template slot="signerType" slot-scope="scope">
        <base-select
          v-model="scope.row.signerType"
          :options="signerTypeOptions"
          :selectedField="['dictId', 'dictName']"
          :disabled="scope.row.documentType == '20'"
          @change="changeSignerType(scope.row,scope.$index)"
        ></base-select>
      </template>
      <!-- 电子章签署人 -->
      <template slot="sealAdminId" slot-scope="scope">
        <base-select
          v-model="scope.row.sealAdminId"
          :options="scope.row.sealAdmins"
          :selectedField="['keyId', 'personnelName']"
          @visible-change="openSealAdminIdeSelect(scope.row,scope.$index)"
          @change="changeSealAdminId(scope.row,scope.$index)"
          ref="signerType"
        ></base-select>
          <!-- :options="scope.row.selectFlag? sealAdmins[scope.row.selectIndex]: scope.row.sealAdmins" -->
      </template>
      <!-- 签署位置 -->
      <template slot="positionType" slot-scope="scope">
        <base-select
           v-model="scope.row.positionConfig.positionType"
           :options="scope.row.documentType == '50'?positionTypeOptionsOne: positionTypeOptionsTwo"
          :selectedField="['dictId', 'dictName']"
          @change="changePositionType(scope.row,scope.$index)"
        />
      </template>
      <!-- 签署明细 -->
        <template slot="posKeyword" slot-scope="scope">
        <el-input
        v-model="scope.row.positionConfig.posKeyword"
        :disabled="scope.row.documentType == '20' && scope.row.positionConfig.positionType !== 'KEYWORD'"
        @change="changePosKeyword(scope.row,scope.$index)"
        />
      </template>
      <template slot="previewShow" slot-scope="scope">
        <icon-button
         content="预览"
         icon="iconfont iconchakan"
         :class="{isPreviewShowPass:scope.row.positionConfig.isPreviewShowPass}"
         @click="previewShow(scope.row,scope.$index)"
         ref="previewShow"
        >
        <!-- v-if="scope.row.documentType !== '20' || scope.row.positionConfig.positionType === 'KEYWORD'" -->
        </icon-button>
        <!-- {{scope.row.positionConfig.isPreviewShowPass}} -->
        <icon-button
         content="增加签章"
         icon="iconfont iconzhuijia"
         @click="addSignList(scope.row,scope.$index)"
         v-if="scope.row.documentType !== '20'"
        >
         </icon-button>
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
         content="删除签章"
         icon="iconfont iconshanchu1"
         @click="delSignList(scope.row,scope.$index)"
         v-if="scope.row.positionConfig.isAddData || scope.row.fileSource == 1"
        >
        </icon-button>
      </template>
    </base-table>
    <div slot="footer" class="dialog-footer">
      <base-button type="primary" @click="save" label="保存" />
      <base-button type="primary" @click="submit" label="提交" />
      <base-button @click="close" label="关闭" type="default" />
    </div>
  </base-dialog>
  <!-- 文件预览 -->
   <!-- <pre-view-dialog
      :previsible.sync="previsible"
      :count="count"
      :fileId="fileId"
      :fileType="fileType"
      @dowload="dowload(fileId)"
    /> -->
  <!-- 文件预览 -->
    <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="count" />
  </div>
</template>

<script>
import PreView from '@/components/pre-view/pre-view.vue'
import { signApi } from '@/api/businessApi'
// import preViewDialog from './pre-view-dialog.vue'
import { signatoryTable } from '../utils/config'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { getDictLists } from '@/filters/fromDict'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { supplierApi, certifyApi } from '@/api/companyApi'
import { downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import { contactApi } from '@/api/contactApi'

export default {
  components: { BaseTable, BaseDialog, BaseSelect, BaseButton, IconButton, PreView },
  props: {
    sealAdmins: {
      type: Array,
      default: () => []
    },
    signShow: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      gysOptions: [], // 供应商选项
      positionTypeOptions: getDictLists('SIGN_POSITION_TYPE'), // 盖章位置选项
      posKeywordOptions: [{}],
      signListData: [],
      previsible: false, // 预览弹窗
      count: 0,
      fileId: '',
      fileType: 'pdf', // 全是pdf
      categoryOptions: [
        { value: '0', label: '认证后签署' },
        { value: '1', label: '平台签署' }
      ],
      mainPartData: [] // 平台企业选项
    }
  },
  // 计算属性 类似于data概念
  computed: {
    visible: {
      get () {
        return this.signShow
      },
      set (val) {
        this.$emit('update:signShow', val)
      }
    },
    columns () {
      return signatoryTable(this)
    },
    signerTypeOptions () {
      const newData = getDictLists('SEAL_TYPE').filter(item => {
        if (item.dictId === '1' || item.dictId === '2') {
          return item
        }
      })
      return newData
    },
    // 合同文件签署位置集合
    positionTypeOptionsOne () {
      const arr = this.positionTypeOptions.filter(item => {
        if (item.dictId === 'KEYWORD') {
          return item
        }
      })
      return arr
    },
    // 基础资料签署位置集合
    positionTypeOptionsTwo () {
      const arr = this.positionTypeOptions.filter(item => {
        if (item.dictId !== 'KEYWORD') {
          return item
        }
      })
      return arr
    }
  },
  // 监控data中的数据变化
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          this.getGysList()
          this.setColSpan()
          this.listbylabel()
        })
        this.signListData = this.tableData
        this.signListData.forEach(item => {
          // 如果签章类型为0 没有该类型，置空
          if (item.signerType === '0') {
            this.$set(item, 'signerType', '')
          }
        })
      }
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // this.getGysList()
  },
  // 方法集合
  methods: {
    // 合并表头操作栏位
    setColSpan () {
      const n = document.getElementsByClassName('el-table__header')[3].rows[0].cells
      n[11].colSpan = 2
      n[12].style.display = 'none'
    },
    // 平台企业
    listbylabel () {
      contactApi.listbylabel({ enterpriseLabel: 5 }).then((res) => {
        if (res.success) {
          this.mainPartData = res.data
        }
      })
    },
    // 增加签章
    addSignList (row, index) {
      // 组装新数据
      const addData = JSON.parse(JSON.stringify(row))
      addData.positionConfig = {
        category: '0',
        docConfigId: '',
        havePositionOrder: 0,
        higth: 0,
        keyId: '',
        lPoxX: 0,
        lPoxY: 0,
        name: '',
        posKeyword: '',
        positionConfigSource: 0,
        positionExistFlag: true,
        positionType: '',
        productFileId: '',
        relyPath: '',
        relyPosition: '',
        signerId: '',
        specialPosition: '',
        width: 0,
        xOffset: 100, // 默认x轴偏移量
        yOffset: 0,
        isPreviewShowPass: false, // 追加数据，清空查看状态
        isAddData: true
      }
      addData.signerType = ''
      addData.signName = ''
      addData.sealAdmins = []
      addData.sealAdminId = ''
      // addData.isAddData = true // 手动加入数据判断是否是该页面新增
      // addData.positionConfig.isPreviewShowPass = false
      delete addData.keyId
      const newTableData = JSON.parse(JSON.stringify(this.signListData))
      newTableData.splice(index, 0, addData)
      // 格式化合并
      this.signListData = this.$parent.filterData(newTableData, 'finFileListId', 'finFileListNameRowSpan')
      this.signListData = this.$parent.newFilterData(newTableData, 'finFileListDetailId', 'finFileListDetailNameRowSpan')
      console.log(this.signListData, 'this.signListData')
    },
    // 删除签章
    async   delSignList (row, index) {
      const obj = this.signListData.filter(item => item.finFileListDetailId === row.finFileListDetailId)
      if (obj.length === 1) {
        return this.warning('必须保留一个签署人')
      }
      // positionConfigSource ===0 ||''  不调用接口，1调用换参数
      let params = { keyId: row.keyId }
      if (row.positionConfig.positionConfigSource === '1') {
        params = { keyId: row.positionConfig.keyId }
        const res = await signApi.deleteFinSigner(params)
        if (res.data) {
          this.signListData.splice(index, 1)
          this.success('删除成功')
        } else {
          return this.warning('删除失败')
        }
      } else {
        this.signListData.splice(index, 1)
      }
      // 格式化合并
      this.signListData = this.$parent.filterData(this.signListData, 'finFileListId', 'finFileListNameRowSpan')
      this.signListData = this.$parent.newFilterData(this.signListData, 'finFileListDetailId', 'finFileListDetailNameRowSpan')
    },
    // 处理预览数据
    previewShowData (row) {
      const [obj] = row.sealAdmins.filter(ele => row.sealAdminId === ele.keyId)
      // console.log(obj, 'obj')
      const data = {
        finFileListDetailId: row.finFileListDetailId,
        keyId: row.keyId,
        identNo: row.signerType === '1' ? obj?.idCard : obj?.creditCode || row.identNo,
        identType: row.signerType === '1' ? '0' : 'N',
        sealAdminId: row.sealAdminId,
        signName: row.signerType === '1' ? obj?.personnelName : row.signName,
        signerType: row.signerType,
        signPosition: row.positionConfig,
        ispositionType: row.positionConfig.positionType, // 用来判断签署位置是否为空
        posKeyword: row.positionConfig.posKeyword, // 用来判断签署位置明细是否为空
        documentType: row.documentType // 用来区分是合同文件还是基础资料文件
      }
      // 如果是新增的数据，需要删除keyid 由后端新建
      if (row.positionConfig.isAddData) {
        delete data.keyId
      }
      // 判断签章类型，传不同的值
      if (data.signerType === '1') {
        this.$set(data.signPosition, 'higth', 80)
        this.$set(data.signPosition, 'width', 80)
      } else if (data.signerType === '2') {
        if (row.category === '0') {
          this.$set(data.signPosition, 'higth', data.signPosition.higth || 190)
          this.$set(data.signPosition, 'width', data.signPosition.width || 190)
        } else {
          this.$set(data.signPosition, 'higth', data.signPosition.higth || 135)
          this.$set(data.signPosition, 'width', data.signPosition.width || 135)
        }
      }
      for (const key in data) {
        // console.log(key, data[key], !data[key], 'data[key]')
        if (!data[key]) {
        // 处理基础资料签署位置明细可以为空
          if (row.documentType === '20' && key === 'posKeyword') {
          } else {
            return false
          }
        }
      }
      return data
    },
    // 预览文件 》 让客户确认文件是否盖章正确，必须所有预览成功才能提交
    previewShow (row, index) {
      console.log(row, 'row')
      const showArr = this.signListData.filter(item => item.finFileListDetailId === row.finFileListDetailId)
      const submitData = []
      for (let i = 0; i < showArr.length; i++) {
        if (this.previewShowData(showArr[i])) {
          submitData.push(this.previewShowData(showArr[i]))
        } else {
          return this.warning('请完善该文件第' + (i + 1) + '项数据后，再进行预览')
        }
      }
      // 签章位置包含关系判断
      const posKeywordArr = submitData.map(item => item.signPosition.posKeyword)
      const posKeywordArrCopy = JSON.parse(JSON.stringify(posKeywordArr))
      for (let i = 0; i < posKeywordArr.length; i++) {
        for (let j = 1; j < posKeywordArrCopy.length; j++) {
          if (i !== j) {
            if (posKeywordArr[i].includes(posKeywordArrCopy[j]) || posKeywordArrCopy[j].includes(posKeywordArr[i])) {
              this.$stateConfirm({
                title: '提示',
                message: '签章位置明细有包含关系，可能导致盖章位置重叠,是否继续？',
                show: true,
                type: 'warning'
              }).then(() => {
                this.signPreView(row, submitData)
              })
              return
            }
          }
        }
      }
      // 处理后的数据和选中数据一致才继续
      if (submitData.length !== showArr.length) {
        return
      }
      this.signPreView(row, submitData)
    },
    // 预览接口调用
    signPreView (row, submitData) {
      signApi.signPreView(submitData).then(res => {
        if (res.data.success) {
          this.fileId = res.data.fileId
          this.previsible = true
          this.$nextTick(() => {
            this.count++
          })
          this.signListData.forEach((item, i) => {
            if (item.finFileListDetailId === row.finFileListDetailId) {
              this.$set(this.signListData[i].positionConfig, 'isPreviewShowPass', true)
            }
          })
        } else {
          this.error(res.data.failMsg)
        }
      })
    },

    // 下载文件
    dowload (fileId) {
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    // 改变一个选项,全部恢复按钮颜色
    changeIsPreviewShowPass (row, index) {
      this.signListData.forEach((item, i) => {
        if (item.finFileListDetailId === row.finFileListDetailId) {
          this.$set(this.signListData[i].positionConfig, 'isPreviewShowPass', false)
          // console.log(this.signListData[i], 'this.signListData[i]')
        }
      })
      // this.$refs.previewShow.$forceUpdate()
    },
    // 改变机构
    changeCategory (row, index) {
      this.$set(this.signListData[index], 'signName', '')
      this.$set(this.signListData[index], 'sealAdminId', '')
      this.changeIsPreviewShowPass(row, index)
    },
    // 改变盖章主体
    changeSignName (row, index) {
      this.$set(this.signListData[index], 'sealAdminId', '')
      this.changeIsPreviewShowPass(row, index)
    },
    // 改变签章类型
    changeSignerType (row, index) {
      this.$set(this.signListData[index], 'sealAdminId', '')
      this.changeIsPreviewShowPass(row, index)
    },
    // 改变选项的时候
    changeSealAdminId (row, index) {
      this.changeIsPreviewShowPass(row, index)
      // 选择数据时,电子章类型为企业时
      if (row.sealAdminId && row.signerType === '2') {
        this.signListData.forEach((ele, i) => {
          // 同一个公司下面的企业章，没有选择签署人的数据自动选中
          if (ele.signerType === '2' && row.signName === ele.signName && !ele.sealAdminId) {
            this.$set(this.signListData[i], 'sealAdmins', row.sealAdmins)
            this.$set(this.signListData[i], 'sealAdminId', row.sealAdminId)
            this.changeIsPreviewShowPass(ele, i)
          }
        })
      }
    },
    // 改变签章位置
    changePositionType (row, index) {
      this.changeIsPreviewShowPass(row, index)
    },
    // 改变签署明细
    changePosKeyword (row, index) {
      this.changeIsPreviewShowPass(row, index)
    },
    // 打开签署人下拉
    openSealAdminIdeSelect (row, index) {
      if (row.signName && row.signerType) {
        let companyKeyId = ''
        if (row.category === '0') {
          for (let i = 0; i < this.gysOptions.length; i++) {
            if (this.gysOptions[i].enterpriseName === row.signName) {
              companyKeyId = this.gysOptions[i].keyId
            }
          }
        } else if (row.category === '1') {
          for (let i = 0; i < this.mainPartData.length; i++) {
            if (this.mainPartData[i].enterpriseName === row.signName) {
              companyKeyId = this.mainPartData[i].keyId
            }
          }
        }
        console.log(companyKeyId, 'companyKeyId')
        // 企业，个人， 查询的时候互换位置
        const param = { enableStatus: 1, enterpriseId: companyKeyId, sealAdminType: row.signerType === '1' ? '2' : '1' }
        certifyApi.getSealAdminDetail(param).then(res => {
          if (res.data) {
            this.$set(this.signListData[index], 'sealAdmins', res.data)
          }
        })
      } else {
        this.$set(this.signListData[index], 'sealAdmins', [])
      }
      console.log(this.signListData, 'this.signListData')
      // this.$refs.signerType.$forceUpdate()
    },
    // 盖章主体》公司名
    getGysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.gysOptions = res.data
        }
      })
    },

    close () {
      this.visible = false
      let pass = true
      this.signListData.forEach((item) => {
        if (item.signerType === '3') { // 平台章的话就不做校验
          pass = true
        } else {
          if (!item.sealAdminId && item.signerConfigIdRowSpan !== 0) {
            pass = false
          }
        }
      })
      if (!pass) {
        this.$emit('update:chosePass', false)
      }
    },
    // 获取文件预览状态
    getAllPreviewShowPass () {
      if (this.signListData.length > 0) {
        let allPreviewShowPass = true
        this.signListData.forEach((item) => {
          if (item.signerType === '3') { // 平台章的话就不做校验

          } else {
            if (!item.positionConfig.isPreviewShowPass) {
              allPreviewShowPass = false
            }
          }
        })

        if (!allPreviewShowPass) {
          this.warning('请完成所有签署文件预览,再进行提交')
        }
        return allPreviewShowPass
      } else {
        this.warning('请先选择签署人')
        return false
      }
    },
    // 提交
    submit () {
      let pass = true
      // let allPreviewShowPass = true
      let fileName = ''
      this.signListData.forEach((item) => {
        if (item.signerType === '3') { // 平台章的话就不做校验
          pass = true
          fileName = item.finFileListName
        } else {
          if (!item.sealAdminId && item.signerConfigIdRowSpan !== 0) {
            pass = false
            fileName = item.finFileListName
          }
          // if (!item.positionConfig.isPreviewShowPass) {
          //   allPreviewShowPass = false
          // }
        }
      })
      if (!pass) {
        this.warning('请选择' + fileName + '的签署人')
        return false
      }
      // if (!allPreviewShowPass) {
      //   this.warning('请完成所有文件预览,再进行提交')
      //   return false
      // }
      const newTableData = this.constructionData(this.signListData)
      signApi.saveOrUpdateSignConfig(newTableData).then((res) => {
        if (res.data) {
          this.$emit('update:chosePass', true)
          this.success('提交成功')
          this.close()
        } else {
          this.error('提交失败')
        }
      })
    },
    //  构造数据
    constructionData (data) {
      const newTableData = JSON.parse(JSON.stringify(data))
      // 构造数据
      newTableData.forEach((item) => {
        const [obj] = item.sealAdmins ? item.sealAdmins.filter(ele => item.sealAdminId === ele.keyId) : [null]
        console.log(obj, 'obj')
        if (obj) {
          if (item.signerType === '1') {
            this.$set(item, 'identNo', obj.idCard)
            this.$set(item, 'signName', obj.personnelName)
            this.$set(item.positionConfig, 'higth', 80)
            this.$set(item.positionConfig, 'width', 80)
            this.$set(item, 'identType', '0')
            this.$set(item, 'signerId', obj.personId)
          } else if (item.signerType === '2') {
            this.$set(item, 'identNo', obj.creditCode || item.identNo)
            if (item.category === '0') {
              this.$set(item.positionConfig, 'higth', item.positionConfig.higth || 190)
              this.$set(item.positionConfig, 'width', item.positionConfig.width || 190)
            } else {
              this.$set(item.positionConfig, 'higth', item.positionConfig.higth || 135)
              this.$set(item.positionConfig, 'width', item.positionConfig.width || 135)
            }
            this.$set(item, 'identType', 'N')
            this.$set(item, 'signerId', obj.enterpriseId)
          }
          if (item.category === '1' && item.signerType === '1') {
            console.log(obj.enterpriseName, 'obj.enterpriseName')
            this.$set(item, 'signName', obj.enterpriseName)
          }
        }
      })
      return newTableData
    },
    // 保存
    save () {
      const newTableData = this.constructionData(this.signListData)

      signApi.saveSignConfig(newTableData).then((res) => {
        if (res.data) {
          this.$emit('update:chosePass', true)
          this.success('保存成功')
          this.close()
        } else {
          this.error('保存失败')
        }
      })
    },
    // 获取列表
    getFilelist () {},

    // 合并
    objectSpanMethod ({ row, column }) {
      if (column.property === 'finFileListName') {
        const _row = row.finFileListNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'fileSource') {
        const _row = row.finFileListDetailNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'finFileListDetailName') {
        const _row = row.finFileListDetailNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'previewShow') {
        const _row = row.finFileListDetailNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'signerType' || column.property === 'sealAdminId') {
        const _row = row.signerConfigIdRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    }
  }

}
</script>
<style lang='scss' scoped>
.isPreviewShowPass{
  /deep/.iconfont{
    color: #050 !important;
  }
}
/deep/.el-dialog{
  width: 90% !important;
}

</style>
